<style lang="less">
  @import "./login3.less";
</style>

<template>
<div style="width:100%;height:100%;background: #fff;">
  <div class="login-top">
    <div class="login-warp">
      <div class="login-logo">
        <div style="width:80px;height:80px;">
          <img src="./image/logo.png">
        </div>
        <div style="width: 160px;height: 62px;padding-top:7px;">
          <h1 style="letter-spacing:4px">律师好帮手</h1>
          <h3 style="text-align:center;letter-spacing:0px">my.easyls.cn</h3>
        </div>
        <div style="width: 96px;height: 32px;padding-top:12px;padding-left:10px;">
          <img src="./image/welcome.png">
        </div>
      </div>
      <div class="login-qq">
        <div style="margin-left: 200px;">
          <img src="./image/qq.png">
        </div>
      </div>
    </div>
  </div>
  <div class="login-main">
    <div class="login-warp">
      <div class="login-ad"><img width="472" height="415" alt="" src="./image/banner images.png"></div>
      <div class="login-form">
        <div class="login-box">
          <div class="qrcode">
            <div id="qrCode" ref="qrCodeDiv" class="qrcode_img" v-bind:class="{qrcode_expired: isTimeOut}"></div>
          </div>
          <div class="qrcode_text" v-if="isTimeOut==false">
            <p class="sub_title">打开律师好帮手App扫码登录</p>
          </div>
          <div class="qrcode_refresh" v-if="isTimeOut==true">
            <button class="refresh_button" v-on:click="refresh()">请点击刷新</button>
          </div>
          <div class="qrcode_text" v-if="isTimeOut==true">
            <p class="sub_title">二维码已失效，请点击刷新</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="login-notice">
	  <p style="">使用提示：用IE浏览器界面有可能变形，请用<a href="https://www.google.cn/chrome/">谷歌</a>或者<a href="http://www.firefox.com.cn/download/">火狐</a>浏览器。</p>
  </div>
  <div style="background: #fff;">
    <ul class="app-d">
      <li class="special">
        <div>
          <img src="./image/tg_android.png" width="100" height="100"/>
          <p style="margin-top: 40px;padding: 5px;font-size: 14px;">安卓App下载</p>
        </div>
      </li>
      <li class="special">
        <div>
          <img src="./image/tg_ios.png" width="100" height="100" alt=""/>
          <p style="margin-top: 40px;padding: 5px;font-size: 14px;">苹果App下载</p>
        </div>
      </li>
    </ul>
  </div>
  <div class="ft">
    <p class="p1"><span>福州盛亭信息科技有限公司版权所有 &copy; 2021</span> <span>|</span> <span><a href="https://beian.miit.gov.cn" target="_blank">闽ICP备2021015908号</a></span></p>
  </div>
</div>
</template>

<script>
import { 
  webLoginInit,
  webLogin,
  getOtherSet,
  userInfo,
} from "@/api/index";
import util from "@/libs/util.js";
import QRCode from "qrcodejs2";
import { ws } from "@/api/index";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
var client;

export default {
  name: "qrCode",
  data() {
    return {
      timer: 0,
      webKey:"",
      isTimeOut:false
    };
  },
  mounted() {
    this.init();
    this.timer = setInterval(() => {
      this.isTimeOut = true;
    }, 10*60*1000);
    // }, 5*1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    bindQRCode: function () {
      let qr = new QRCode(this.$refs.qrCodeDiv, {
        text: this.webKey,
        width: 180,
        height: 180,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
      qr._el.title='';
    },
    refresh(){
      this.isTimeOut = false;
      this.closeCode();
      this.loginInit();
    },
    closeCode () {
      this.$refs.qrCodeDiv.innerHTML = ''
    },
    init() {
      this.loginInit();
    },
    loginInit() {
      webLoginInit({
        webKey: this.webKey
      }).then((res) => {
        if (res.success) {
          this.webKey = res.result.webKey;
          this.bindQRCode();
          this.connect();
        } else {
          console.log("error");
        }
      });
    },
    connect() {
      var headers = {};
      // client = Stomp.over(new SockJS(ws));
      client = Stomp.over(new SockJS(ws,null,{timeout:15000}));
      client.connect(headers, this.onConnected, this.onFailed);
    },
    onConnected(frame) {
      var queueSubscription = client.subscribe(
        "/user/"+this.webKey+"/queue/subscribe",
        this.responseCallback
      );
    },
    onFailed(frame) {
    },
    responseCallback(frame) {
      this.login(frame.body,this.webKey);
    },
    login(appToken,webKey) {
      webLogin({
        appToken: appToken,
        webKey:webKey,
      }).then((res) => {
        if (res.success) {
          this.afterLogin(res);
        }
      });
    },

    afterLogin(res) {
      let accessToken = res.result;
      this.setStore("accessToken", accessToken);
      getOtherSet().then((res) => {
        if (res.result) {
          let domain = res.result.ssoDomain;
          Cookies.set("accessToken", accessToken, {
            domain: domain,
            expires: 7,
          });
        }
      });
      // 获取用户信息
      userInfo().then((res) => {
        if (res.success) {
          // 避免超过大小限制
          delete res.result.permissions;
          let roles = [];
          res.result.roles.forEach((e) => {
            roles.push(e.name);
          });
          this.setStore("roles", roles);
          this.setStore("saveLogin", false);
          Cookies.set("userInfo", JSON.stringify(res.result));

          this.setStore("userInfo", res.result);
          this.$store.commit("setAvatarPath", res.result.avatar);
          // 加载菜单
          util.initRouter(this);
          this.$router.push({
            name: "home_index",
          });
        } else {
          this.loading = false;
        }
      });
    },
  },
  stompClient: {
    monitorIntervalTime: 1000,
    stompReconnect: true,
    timeout(orgCmd) {},
  },
};
</script>